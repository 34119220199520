import 'react-app-polyfill/ie11'
import 'core-js/fn/array/find';

import React, {createContext, useState} from 'react';
import { render } from 'react-dom';
import { BrowserRouter as Router, Link, Route, Switch, withRouter } from 'react-router-dom'
import {loadStripe} from '@stripe/stripe-js';
import {
  Elements
} from '@stripe/react-stripe-js';
import App from './App';
import CustomComponentsProvider from './Custom/CustomComponentsProvider'
import './styles.css';
import {getRestaurantId} from "./utils"
import getCustomComponent from './Custom/getCustomComponent'
import ThemeContext from './Context/ThemeContext'

// import FontFaceObserver from 'fontfaceobserver'

import * as Sentry from '@sentry/browser';

Sentry.init({dsn: "https://0ce0acbc8af44ae59ffaf3333c95fd7e@o400531.ingest.sentry.io/5259050"});
window.Sentry = Sentry

//
// const font = new FontFaceObserver('open-sans');
// font.load().then(function () {
//   console.log('My Family has loaded');
// });


const STRIPE_PUBLIC_KEY = (process.env.NODE_ENV === 'development') ? 'pk_test_4StUZOAUBEimWV33hJZZn5f5' : 'pk_live_Sq151n5SUu6qAtZDKtSdw0tv'
const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

let restId = getRestaurantId()
const rootElement = document.getElementById('root');
// let renderFunc = rootElement.hasChildNodes() ? hydrate : render
let renderFunc = render // database often change which result in DOM mismatch, so never hydrate

const locations = getCustomComponent() && getCustomComponent().locations
const MultilocationHome = getCustomComponent() && getCustomComponent().MultilocationHome
const RootRouterContext = React.createContext(null)
export {RootRouterContext}
function LocationRouter ({history, basePath, shopId, cssGlobalPrefix}) {
  return (
    <RootRouterContext.Provider value={{rootHistory: history}}>
      <Router basename={basePath}>
      <CustomComponentsProvider locationId={shopId}>
        <App restId={shopId} cssGlobalPrefix={cssGlobalPrefix}></App>
      </CustomComponentsProvider>
      </Router>
    </RootRouterContext.Provider>
  )
}
const LocationWithRootRouter = withRouter(LocationRouter)

function Root () {
  var preferredTheme = '';
  try {
    preferredTheme = localStorage.getItem('theme');
  } catch (err) { }
  const [theme, setTheme] = useState(preferredTheme)
  window.__preferredTheme = theme
  function setSaveTheme (newTheme) {
    console.log('setting: ', newTheme)
    setTheme(newTheme)
    try {
      localStorage.setItem('theme', newTheme);
    } catch (err) {}
  }
  window.__setPreferredTheme = setSaveTheme
  return(
    <ThemeContext.Provider value={{
      themeName: theme,
      setTheme: setSaveTheme
    }}>
      <Router>
        <Switch>
          {locations && locations.map(({shopId, basePath, cssGlobalPrefix}) => {
            console.log('branch location: ', shopId)
            return(
                <Route key={basePath} path={basePath} render={() => (
                    <LocationWithRootRouter basePath={basePath} shopId={shopId} cssGlobalPrefix={cssGlobalPrefix} />
                  )} />

            )
          })}

          {MultilocationHome && <Route component={MultilocationHome} />}

          <Route>
            <CustomComponentsProvider>
              <App restId={restId}></App>
            </CustomComponentsProvider>
          </Route>
        </Switch>

      </Router>
    </ThemeContext.Provider>
  )
}


renderFunc(
  <Elements stripe={stripePromise}>
    <Root></Root>
  </Elements>
  ,
  rootElement
)
