import React from 'react'
import {useState, useReducer, useEffect, useRef} from 'react'
import {Link, withRouter} from 'react-router-dom'
import './PostAddToCartQuickNav.scss'


function PostAddToCartQuickNav ({ history, productId }) {
  const focusRef = useRef()
  useEffect(function focus () {
    if (focusRef.current) {
      console.log("focusing...")
      focusRef.current.focus()
    }
  }, [])


  function goBack (e) {
    try {e.preventDefault()} catch (e) {}
    try {
      window['product-id-to-focus'] = productId
    } catch(e) {}
    history.push({pathname: '/order'})
  };

  return(
    <div className="post-add-quick-nav product-wrapper">
      <div className="content product">
        <h1 className="text"
          ref={focusRef}
          tabindex={-1}
          >
          Item successfully added to cart.
        </h1>
        <div className="actions">
          {productId && <a href="#" onClick={goBack}>back to where I left off</a>}
          <br/>
          <Link to="/order">back to start of menu</Link>
          <br/>
          <Link to="/checkout">go to cart</Link>
        </div>
      </div>
    </div>
  )
}

export default withRouter(PostAddToCartQuickNav)
