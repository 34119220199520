import React from 'react'
// import './specials.scss'
import AutoMenu from '../../react/AutoMenu'
import {Link} from 'react-router-dom'
export default function () {
  return(
    <div className="catering-page">
      <article className="page-content">
        <h1>Akashi Catering</h1>

        <p>Catering menu coming soon, contact us via email for info now:</p>

        <p>
          <a href="mailto:akashi@afoodapart.com">akashi@afoodapart.com</a>
        </p>

      </article>

      <div className="full-menu-link-wrapper">
        <Link to="/order" className="button">Go to Full Menu</Link>
      </div>
    </div>
  )
}
