import React, { Component, PureComponent } from 'react';
import {NavLink, Link, Redirect} from 'react-router-dom'
import './index.scss'
import Catering from './Catering'
import OurStory from './OurStory'
import Form from './Form'
import Wine from './Wine'
export { Catering as catering }
export { OurStory as about }

export const customOrderAppPaths = ["/menu", "/order"]


class TwoGuysFromItalyGlendale extends Component {
  render() {
    return (
      <div className="twoguysfromitalyglendale">
      </div>
    );
  }
}


export const pages = [
  {path: "/order", label: "Order"},
  {path: "/menu", label: "Menu"},
  {path: "/alcohol", label: "Alcohol ToGo", component: Wine},
  {path: "/wp-content/uploads/2018/05/MENU_NEW_012618.pdf", hidden: true, component: () => <Redirect to="/menu" />},
  // {path: "/catering", label: "Catering", component: Catering},
  // {path: "/about", label: "Our Story", component: OurStory},
]

export class SiteNav extends PureComponent {
  render() {

    return (
    <nav className="pages-nav">
      <ul>
        {pages.map(({path, label, hidden}) => (
          hidden ? null : <li><NavLink className="page-link" to={path}>{label}</NavLink></li>
        ))}
      </ul>
    </nav>
  )}
}

export class OrderingHeader extends PureComponent {
  render() {
    return (
      <div className="order-benefits-wrapper">
        <div className="order-benefits">

          <div
            className="text"
             >


            <p>Takeout Ordering</p>
          </div>
        </div>
      </div>
    );
  }
}

const sections = [
  {
    title: "Happy Hour",
    content: "Every Day 3pm-7pm. All Day Saturday",
    backgroundImage: ""
  }
]

// logo link: https://d2abh92eaqfsaa.cloudfront.net/image/upload/u7ryxhrj9dapgz8pqmny
export class Header extends PureComponent {
  render() {
    return (
      <div className="landing-page-wrapper">

        <div className="sections-wrapper">
          <section className="section banner">
            <div className="content"
              style={{
                backgroundImage: `url(https://afag.imgix.net/akashi-sushi-kirby/bg-1.jpg?w=1200&auto=format)`,
              }}
              >
                <div className="text">
                  <h1><div className="bg">Dine-In Available</div></h1>
                  {/* <Form></Form> */}
                  <div className="button-wrapper">

                    <Link
                      className="order-online-button"
                      to="/order">Order Pickup
                    </Link>
                  </div>
                  {/* <h1><div className="bg"><Link to="/alcohol">Alcohol ToGo</Link><br/>Exclusively Here</div></h1> */}
                </div>

              </div>
          </section>

          {/* <section className="section banner story">
            <h2>Discover Our Story</h2>

            <h3>TASTE THE ART OF SUSHI</h3>

            <article className="our-story">
              <p><strong>Are you craving Chinese, Thai, or Japanese?</strong></p>

              <p>You have to come to Akashi Asian Sushi Cuisine & Wine Bar for the freshest and most authentic Chinese, Thai, and Japanese</p>

              <p>food in Houston, TX; Lake Conroe, TX; Katy, TX; and beyond. Every day our chefs are making high-quality meals that leave our customers satisfied and happy! As a family-owned and -operated business, we make every patron feel at home.</p>

              <p>​In the skilled and artful hands of our chefs, Akashi Asian Sushi Cuisine & Wine Bar has been elevated to an exquisite level as yet unseen in Houston. When you come through the doors, you immediately know you are about to experience a remarkable and memorable dining treat.</p>

              <p>What you see on the plates at Akashi Asian Sushi Cuisine & Wine Bar goes well beyond sheer expertise. Begin with the finest seafood, add exceptionally talented chefs, and an ambiance that’s unmatched in the Houston area. Whether slicing sashimi, creating new Chinese inspired dishes, greeting guests as they enter into the architecturally unique restaurant, our team strives to offer each diner an opportunity to relax, enjoy and experience a meal to remember.</p>

              <p>Infuse that with impeccable attention to detail, an expertly curated Japanese sake collection, drinks specials each evening at a beautifully design bar, and genuinely warm and welcoming service from a knowledgeable and attentive team. Together, these elements combine to make any meal at Akashi Asian Sushi Cuisine & Wine Bar a truly extraordinary dining experience.</p>

              <p>What you’re left with when you leave Akashi Sushi is the memories of how fresh and delicious the food was, how intimate and lovely the ambience was and how you can’t wait till the next time you come back.</p>
            </article>

          </section>

          <section className="section banner story">
            <h2>Our Features</h2>

            <h3>Perfect fusion of Japanese food, Chinese food, and Bar Culture</h3>

            <article className="our-story">

            </article>

          </section>

          <section className="section banner story">
            <h2>Happy Hour</h2>


            <h3>EVERY DAY 3PM-7PM <br/> ALL DAY SATURDAY</h3>

            <p>Hard day at work?</p>

            <article className="our-story">
              <p>Well, come relax at Akashi Asian Sushi Cuisine & Wine Bar’s happy hour. We have an assortment of wines, cocktails, and beer, served at our wine bar. Whether it is a domestic beer you want or a martini with a twist we have it all. So come on down to Akashi Asian Sushi Cuisine & Wine Bar, order your favorite glass of wine and a roll of sushi and finally get to enjoy your day.</p>
            </article>

          </section> */}


          <section className="section">
            <div className="content"
              style={{
                backgroundImage: `url(https://images.unsplash.com/photo-1562436261-5c8371206f91?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=600&q=80)`,

              }}
              >
              <div className="text">
                <h2>Menu</h2>
                <Link to="/menu">view menu</Link>
              </div>
            </div>
          </section>

          <section className="section">
            <div className="content"
              style={{
                backgroundImage: `url(https://images.unsplash.com/photo-1553621042-f6e147245754?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=600&q=80)`,

              }}
              >
              <div className="text">
                <h2>TOGO<br/>Beer, Sake & More</h2>
                <Link to="/alcohol">view alcohol</Link>
              </div>
            </div>
          </section>

          <section className="section">
            <div className="content"
              style={{
                backgroundImage: `url(https://afag.imgix.net/akashi-sushi-kirby/bar.jpg?w=600)`,

              }}
              >

              <div className="text">
                <h2>Curbside Pickup</h2>
                <a href="https://www.google.com/maps/place/Akashi/@29.6915629,-95.4131376,15z/data=!4m2!3m1!1s0x0:0x10bfc79ae9528aa9?sa=X&hl=en&ved=2ahUKEwimkaShrJrkAhXhpVkKHWA1BNEQ_BIwCnoECA8QCA">
                8216 Kirby Drive, Houston
                </a>
                {/* <p>Glendale, CA 91206</p> */}
                <small><a href="tel:+17136658888">713-665-8888</a></small>
              </div>
            </div>
          </section>


        </div>



        {/* <div className="order-benefits home">

          <div
            className="text"
             >
           <h1><img style={{width: "125px"}}
             src="https://d2abh92eaqfsaa.cloudfront.net/image/upload/u7ryxhrj9dapgz8pqmny" alt="Two Guys from Italy — Glendale"/></h1>
            <small>405 N Verdugo Rd Glendale, CA</small><br/>
            <small><a href="tel:+18182400020">(818)240-0020</a></small>

          </div>
        </div> */}
      </div>
    );
  }
}

const foodImages = [
  'https://afag.imgix.net/two-guys-from-italy-glendale/meat-balls-pasta.jpg?w=800&auto=compress',
  'https://afag.imgix.net/two-guys-from-italy-glendale/porchiuto.jpg?w=800&auto=compress',
  'https://afag.imgix.net/two-guys-from-italy-glendale/pizza.jpg?w=800&auto=compress',
  'http://www.glendaletwoguysfromitaly.com/img/carousel/home_bg_d.jpg',
  'http://www.glendaletwoguysfromitaly.com/img/carousel/home_bg_e.jpg'
]


export function subMenuFilter (subMenu) {
  const tag = subMenu.tag
  if (!tag) {return true}

  const now = new Date()
  const day = now.getDay() // Sunday - Saturday : 0 - 6
  const hours = now.getHours() //0 to 23
  const minutes = now.getMinutes() // 0 to 59
  const isWeekday = [1,2,3,4,5].indexOf(day) !== -1
  const isDinner = (
    (hours >= 15) && (hours <= 22)
  )


  const showDinnerMenu = isDinner
  const showLunchMenu = !isDinner && isWeekday

  const isLunchSubmenu = tag.indexOf('lunch') !== -1
  const isDinnerSubmenu = tag.indexOf('dinner') !== -1

  if (isLunchSubmenu && !showLunchMenu) { return false }
  if (isDinnerSubmenu && !showDinnerMenu) { return false }

  return true
}

export default TwoGuysFromItalyGlendale;
