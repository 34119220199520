import React from 'react'
import {Link} from 'react-router-dom'
import PropTypes from 'prop-types'

import { Subscribe } from 'unstated'
import {injectCartContainer} from './CartContainer'
import {injectShopContainer} from './ShopDataContainer'
import {injectCustomComponents} from '../Custom/CustomComponentsProvider'
import {getRestaurantId} from '../utils'

import Image from './Image'
import makeCloudinaryUrlForPhoto from './makeCloudinaryUrlForPhoto'

function CartMinimized ({cart, shop, settings, customComponents}) {
  if (!shop) {return null}
  let items = cart.state.products
  let numberOfItems = items.length || 0
  let {
    logo,
  } = shop
  // console.log(shop.name)
  const logoImage = makeCloudinaryUrlForPhoto(logo, {height: 200})
  const cashback = cart.state.cashback
  const showCashback = Number(cashback) && (cashback > 0)
  return (
    <div className="cart-wrapper">

      <div className="cart minimized">
        <div className="home-link-wrapper">
          <Link to="/" className="home-link">
            {logo && <img
              className="nav-bar-logo"
              // style={{ height: "50px"}}
               src={logoImage} alt={shop.name && shop.name.en}/>}
            <span className="text-logo">{!logo && shop.name && shop.name.en}</span>
          </Link>

        </div>



        <div className="tool-bar">
          {/* {showCashback ? <span className="cashback-amount">
            ${cashback / 100}
          </span> : null} */}
          <Link
            title="Shopping Cart"
            to={{pathname: "/checkout", state: {fromMini: true}}}
            className="checkout-button button">cart ({numberOfItems})</Link>
        </div>



      </div>
    </div>
  )
}




const CartIcon = () => <svg xmlns="http://www.w3.org/2000/svg" className="cart-icon" viewBox="0 0 24 24" version="1" width="50" height="50">
  <path d="M20 20v-8h2L12 3 2 12h2v8l1 1h5v-7h4v7h5l1-1z"/>
</svg>

export default injectCustomComponents(injectCartContainer(injectShopContainer(CartMinimized)))
