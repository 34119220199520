import React from 'react'
import {useState, useReducer, useEffect, useRef} from 'react'
import {Link, withRouter} from 'react-router-dom'
import { Subscribe } from 'unstated';

// import usePrevious from '../utils/hooks/usePrevious'
import useEditProduct from './useEditProduct'
import {ensureShopContainer} from '../react/ShopDataContainer'
import calcItemPrice from '../react/calcItemPrice'
import CartContainer from '../react/CartContainer'
import Modifiers from './Modifiers'
import makeCloudinaryUrlForPhoto from '../react/makeCloudinaryUrlForPhoto'
import isOrderingOnline from '../react/isOrderingOnline'
import {validateCustomerPicksForProduct} from 'afa-shared'
function betaValidate (product) {
  // in case of validation code throwing an error, let customer place order anyways.
  try {
    return validateCustomerPicksForProduct(product)
  } catch (e) {
    window.Sentry.captureException(e)
    console.log(e)
    return { resultsMap: {}, resultsArray: [], validated: true }
  }
}

function findProduct(productId, shop) {
  for (let submenu of shop.subMenus) {
    const sub = (submenu.name && submenu.name.en) || '' // sub menu name 'short name to save bits'
    for (let product of submenu.products) {
      if (product.id === productId) {
        return Object.assign({}, product, {sub})
      }
    }
  }
}

function Product (props) {
  // const [notification, setNotification] = useState('')
  const enableOrdering = props.settings && props.settings.enableOrdering

  useEffect(function scrollToTop () {
    try { window.scrollTo(0,0) } catch (e) { }
  },
  [props.location])

  const focusRef = useRef()
  useEffect(function focus () {
    if (focusRef.current) {
      console.log("focusing...")
      focusRef.current.focus()
    }
  }, [])


  function goBack (e) {
    try {e.preventDefault()} catch (e) {}
    const canGoBack = (props.history.length > 2)
    // let isFromApp = this.props.location.state && this.props.location.state.fromMini
    if (canGoBack) {
      props.history.goBack()
    } else {
      props.history.push({pathname: '/'})
    }
  };

  function goToPostAddScreen (type, productId) {
    props.history.push({ pathname: `/success/${type}/${productId}` })
  }

  console.log('rendering new product view')

  let cart = props.cart
  let shop = props.shop
  let { productId, cartItemId, action } = props
  const injectedCartItemId = cartItemId

  let loadedProduct = productId ? findProduct(productId, shop)
                          : cart.getCartItemById(cartItemId)
  const [product, dispatch, {
    increment, decrement, onQuantityInputChange
  }] = useEditProduct(loadedProduct)

  const isEditing = (props.action === 'edit')

  const [showValidationGuide, setShowValidationGuide] = useState(null)
  function onClickSaveProduct (e) {
    e.preventDefault()
    const validationResult = betaValidate(product)
    if (!validationResult.validated) {
      setShowValidationGuide(true)
      return
    }
    // product validated
    if (isEditing) {
      cart.saveProduct(product)
      // goToPostAddScreen('save', product.productId)
    } else {
      cart.addProduct(product)
      // goToPostAddScreen('add', product.productId)
    }
    goBack()
  }

  if (!product) {return null}
  // console.log(product)
  const {resultsMap, resultsArray, validated} = betaValidate(product)
  let {price, photos, photo, id, price_multiple, note, size, quantity} = product
  let name = product.name && product.name.en
  let description = product.description && product.description.en
  const imageSrc = makeCloudinaryUrlForPhoto(photo, {width: 200, height: 200})
  const saveButtonText = isEditing ? "Save" : "Add to Cart"

  const notification = product.ariaLive || ''
  return (
    <section key={productId} className="product-wrapper">
      <div
        style={{
          position: 'absolute',
          left: "-10000px",
        }}
        aria-live="assertive">{notification}</div>

      <div className="back-link-wrapper">
        <div className="back-link">
          <a href="#" onClick={goBack}>← back to menu</a>
        </div>


      </div>
      <div className="product" >
        {props.settings && !enableOrdering && <section className="offline-messege">online ordering is currently offline, you may still add items to order at a later time.</section>}
        {/* <input type="text" ref={focusRef} aria-hide/> */}
        <header className="product-info">
          <h1 className="info-group">
            <span role="text">
              <span className="customize-text"
                ref={focusRef} tabIndex={-1}>Customize your</span>{" "}
              <span className="name">{name}</span></span>
            {/* <p className="description">{description}</p> */}
          </h1>
          {/* <p>
            {resultsArray.length} required choices,
          </p> */}
          {(imageSrc !== 'no-pic') && <div className="product-photo"><img src={imageSrc} alt=""/></div>}
        </header>

        {/* <div className="prompt">
          <p>Customize it:</p>
        </div> */}

        {/* {this.state.isOrderingOnline ? null : <section className="offline-messege">online ordering is currently offline, you may still add items to order at a later time.</section>} */}
        <div className="quantity panel">
          <h2>
            <label htmlFor="quantity" className="section-title">Quantity</label>
          </h2>
          <div className="control-group">
            <button className="quantity-button" aria-label={"minus one quantity"} onClick={decrement}>−</button>
            <input  className="quantity-input" id="quantity"
              type="number"
              onChange={onQuantityInputChange}
              value={Number(product.quantity) || 0}/>
            <button className="quantity-button" aria-label={"plus one quantity"} onClick={increment}>+</button>
          </div>
        </div>

        <Modifiers
          product={product}
          cart={cart}
          dispatch={dispatch}
          injectedCartItemId={injectedCartItemId} />

        <section className="notes panel">
          <h2>
            <label htmlFor="product-note" className="section-title">note for kitchen:</label>
          </h2>
          <div>
            <textarea
              id="product-note"
              className="note-input"
              onChange={ (event) => {
                  const note = event.target.value
                  dispatch({type: "EDIT_NOTE", payload: note})
                }}
              value={note}
              name="note" rows="2"></textarea>
            </div>
          </section>



          <section className="">
            <div className="prompt">
              {!validated && <div role="text" id="required-actions" className="incomplete-note">
                <p className="instruction">Make ({(resultsArray.length)}) required choices above,</p>
                <p className="small">then add to cart.</p>
              </div>}
              <span>
                <button
                  onClick={onClickSaveProduct}
                  aria-labelledby="required-actions"
                  className={"add-to-cart-button active" + (!validated ? " not-validated" : "")}>
                  <span>{saveButtonText} </span>
                  <span className="price">(${calcItemPrice(product)})</span>
                </button>
              </span>

            </div>
          </section>
        </div>
      </section>
  )
}




const CartItemCardWithRouter = withRouter(ensureShopContainer(Product))



const SubscribedCard = (props) => (
  <Subscribe to={[CartContainer]}>
    {(cart) => {
      if (!cart) {return null}
      return(<CartItemCardWithRouter {...props} cart={cart} /> || null)
    }}
  </Subscribe>
)

export default (
  SubscribedCard
)
