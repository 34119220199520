
import * as Components from "./Akashisushi"

export default function getCustomComponent () {
  return Components
}

export function getShopId () {
  return "akashisushi"
}
