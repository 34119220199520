
export default function applyDiscount(menu) {
  try {

    if (menu.shopId === "bierhaus") {
      return Object.assign({}, menu, {
        subMenus: menu.subMenus.map((subMenu) => {
          if (['july4'].indexOf(subMenu.tag) !== -1) {
            return Object.assign({}, subMenu, {
              // apply discount
              products: subMenu.products.map((product) => {
                return Object.assign({}, product, {
                  originalPrice: product.price,
                  price: Math.floor(product.price * 0.5 * 100) / 100
                })
              })
            })
          }
          return subMenu
        })
      })
    }
    if (["chens"].indexOf(menu.shopId) !== -1) {
      return Object.assign({}, menu, {
        subMenus: menu.subMenus.map((subMenu) => {
          if (subMenu.tag === '50off') {
            return Object.assign({}, subMenu, {
              // apply discount
              products: subMenu.products.map((product) => {
                return Object.assign({}, product, {
                  originalPrice: product.price,
                  price: Math.floor(product.price * 0.5 * 100) / 100
                })
              })
            })
          }
          return subMenu
        })
      })
    }
    if (["themelt"].indexOf(menu.shopId) !== -1) {
      return Object.assign({}, menu, {
        subMenus: menu.subMenus.map((subMenu) => {
          if (subMenu.tag === '50off') {
            return Object.assign({}, subMenu, {
              // apply discount
              products: subMenu.products.map((product) => {
                return Object.assign({}, product, {
                  originalPrice: product.price,
                  price: Math.floor(product.price * 0.5 * 100) / 100
                })
              })
            })
          }
          return subMenu
        })
      })
    }

    if (["pablitostacosnoho"].indexOf(menu.shopId) !== -1) {
      return Object.assign({}, menu, {
        subMenus: menu.subMenus.map((subMenu) => {
          if (true) {
            return Object.assign({}, subMenu, {
              // apply discount
              products: subMenu.products.map((product) => {
                let modgroups = product.modgroups || []
                return Object.assign({}, product, {
                  // originalPrice: product.price,
                  // price: Math.floor(product.price * 0.8 * 100) / 100,
                  modgroups: modgroups.map((modgroup) => {
                    if (!modgroup.modifiers) { return modgroup }
                    return Object.assign({}, modgroup, {
                      modifiers: modgroup.modifiers.filter((modifier) => {
                        return (modifier.name && (modifier.name.indexOf('Fried Oyster') === -1))
                      })
                    })
                  })
                })
              })
            })
          }
          return subMenu
        })
      })
    }

    if (["pablitostacosburbank"].indexOf(menu.shopId) !== -1) {
      return Object.assign({}, menu, {
        subMenus: menu.subMenus.map((subMenu) => {
          if (true) {
            return Object.assign({}, subMenu, {
              // apply discount
              products: subMenu.products.map((product) => {
                let modgroups = product.modgroups || []
                return Object.assign({}, product, {
                  // originalPrice: product.price,
                  // price: Math.floor(product.price * 0.8 * 100) / 100,
                  modgroups: modgroups.map((modgroup) => {
                    if (!modgroup.modifiers) { return modgroup }
                    return Object.assign({}, modgroup, {
                      modifiers: modgroup.modifiers.filter((modifier) => {
                        return (modifier.name && (modifier.name.indexOf('Fried Oyster') === -1))
                      })
                    })
                  })
                })
              })
            })
          }
          return subMenu
        })
      })
    }

    
    if (["frontierwok-", 'sushidon-', 'muiishi-'].indexOf(menu.shopId) !== -1) {
      return Object.assign({}, menu, {
        subMenus: menu.subMenus.map((subMenu) => {
          if (true) {
            return Object.assign({}, subMenu, {
              // apply discount
              products: subMenu.products.map((product) => {
                return Object.assign({}, product, {
                  originalPrice: product.price,
                  price: Math.floor(product.price * 0.9 * 100) / 100
                })
              })
            })
          }
          return subMenu
        })
      })
    }
    if (["kokorolltorrance"].indexOf(menu.shopId) !== -1) {
      return Object.assign({}, menu, {
        subMenus: menu.subMenus.map((subMenu) => {
          if (true) {
            return Object.assign({}, subMenu, {
              // apply discount
              products: subMenu.products.map((product) => {
                if (!product.modgroups) {return product}
                return Object.assign({}, product, {
                  modgroups: product.modgroups.map((modgroup) => {
                    if (!modgroup.modifiers) { return modgroup }
                    return Object.assign({}, modgroup, {
                      modifiers: modgroup.modifiers.filter((modifier) => {
                        return (modifier.name && (modifier.name.indexOf('Dragon Fruit') === -1))
                      })
                    })
                  })
                })
              })
            })
          }
          return subMenu
        })
      })
    }
  if (["unclereds"].indexOf(menu.shopId) !== -1) {
      return Object.assign({}, menu, {
        subMenus: menu.subMenus.map((subMenu) => {
          if (true) {
            return Object.assign({}, subMenu, {
              // apply discount
              products: subMenu.products.map((product) => {
                if (!product.modgroups) {return product}
                return Object.assign({}, product, {
                  modgroups: product.modgroups.map((modgroup) => {
                    if (!modgroup.modifiers) { return modgroup }
                    return Object.assign({}, modgroup, {
                      modifiers: modgroup.modifiers.filter((modifier) => {
                        return (modifier.name && (modifier.name.indexOf('Spinach') === -1))
                      })
                    })
                  })
                })
              })
            })
          }
          return subMenu
        })
      })
    }
    // if (["pablitostacosburbank"].indexOf(menu.shopId) !== -1) {
    //   return Object.assign({}, menu, {
    //     subMenus: menu.subMenus.map((subMenu) => {
    //       if (true) {
    //         return Object.assign({}, subMenu, {
    //           // apply discount
    //           products: subMenu.products.filter((product) => {
    //             let {name, description} = product
    //             name = (name && name.en) || ''
    //             description = (description && description.en) || ''
    //             const noSausageName = (name.toLowerCase().indexOf('chicharron') === -1)
    //             const noSausageDesc = description.toLowerCase().indexOf('chicharron') === -1
    //             return noSausageName && noSausageDesc
    //           })
    //         })
    //       }
    //       return subMenu
    //     })
    //   })
    // }


    if (["newdeal", 'wildcarvery'].indexOf(menu.shopId) !== -1) {
      return Object.assign({}, menu, {
        subMenus: menu.subMenus.map((subMenu) => {
          if (subMenu.tag && (subMenu.tag.indexOf('discount-50-off') !== -1)) {
            return Object.assign({}, subMenu, {
              // apply discount
              products: subMenu.products.map((product) => {
                return Object.assign({}, product, {
                  originalPrice: product.price,
                  price: Math.floor(product.price * 0.5 * 100) / 100
                })
              })
            })
          }
          if (subMenu.tag && (subMenu.tag.indexOf('discount-30-off') !== -1)) {
            return Object.assign({}, subMenu, {
              // apply discount
              products: subMenu.products.map((product) => {
                return Object.assign({}, product, {
                  originalPrice: product.price,
                  price: Math.floor(product.price * 0.7 * 100) / 100
                })
              })
            })
          }
          if (subMenu.tag && (subMenu.tag.indexOf('wine') !== -1)) {
            return Object.assign({}, subMenu, {
              // apply discount
              products: subMenu.products.map((product) => {
                return Object.assign({}, product, {
                  originalPrice: product.price,
                  price: Math.floor(product.price * 0.8 * 100) / 100
                })
              })
            })
          }
          // if (subMenu.tag && (subMenu.tag.indexOf('spirits') !== -1)) {
          //   return Object.assign({}, subMenu, {
          //     // apply discount
          //     products: subMenu.products.map((product) => {
          //       return Object.assign({}, product, {
          //         originalPrice: product.price,
          //         price: Math.floor(product.price * 0.75 * 100) / 100
          //       })
          //     })
          //   })
          // }
          return subMenu
        })
      })
    }


    return menu
  } catch (e) {
    console.log(e)
    return menu
  }

}
