export default function calcPromoDiscount (args) {
  // console.log({shopId, products})
  try {
    const {
      shopId, cartType,
      products, promoCode='', totalPrice
    } = args

    let totalDiscount = 0

    if (shopId === 'tanghuo') {
      if (['mala', 'tang huo', 'foodaddictsla', 'tanghuo8', 'kungfu8', 'tanghuo10'].indexOf((promoCode || '').toLowerCase()) !== -1) {
        totalDiscount += Math.round(totalPrice * 0.10 * 100) / 100
      }
      // Monday Discount Special
      if (['thankyou'].indexOf((promoCode || '').toLowerCase()) !== -1) {
        totalDiscount += Math.round(totalPrice * 0.15 * 100) / 100
        let hasDrink = false
        products.forEach(({sub}) => {
          if (sub.indexOf('Drinks') !== -1) { // is drink
            hasDrink = true
          }
        })
        if (hasDrink) {
          totalDiscount += 1.25
        }
      }
    }
    if (shopId === 'seorae') {
      if (['vivian'].indexOf((promoCode || '').toLowerCase()) !== -1) {
        totalDiscount += Math.round(totalPrice * 0.10 * 100) / 100
      }
    }

    if (shopId === 'themelt') {
      if (['nohotchicken'].indexOf((promoCode || '').toLowerCase()) !== -1) {
        totalDiscount += Math.round(totalPrice * 0.10 * 100) / 100
      }
    }

    if (shopId === 'beachwood') {
      if (['llama','bestpizza'].indexOf((promoCode || '').toLowerCase()) !== -1) {
        let hasPromo, hasPie
        products.forEach(({sub}) => {
          if (sub.indexOf('Promo') !== -1) { // is drink
            hasPromo = true
          }
          if (sub.indexOf('House Pie') !== -1) { // is drink
            hasPie = true
          }
        })
        if (hasPie) {totalDiscount += 0.1}

        if (hasPromo && hasPie) {
          totalDiscount = 13
        }
      }
    }

    if (shopId === 'wokbar') {
      if (['summerbbq'].indexOf((promoCode || '').toLowerCase()) !== -1) {
        totalDiscount += Math.round(totalPrice * 0.15 * 100) / 100
      }
    }
    if (shopId === 'muiishi') {
      if (['muiishi7'].indexOf((promoCode || '').toLowerCase()) !== -1) {
        totalDiscount += Math.round(totalPrice * 0.1 * 100) / 100
      }
    }
    if (shopId === 'pablitostacosburbank') {
      if (['ydaleu'].indexOf((promoCode || '').toLowerCase()) !== -1) {
        totalDiscount += Math.round(totalPrice * 0.2 * 100) / 100
      }
    }
    if (shopId === 'mikaza') {
      if (['nikkei'].indexOf((promoCode || '').toLowerCase()) !== -1) {
        totalDiscount += Math.round(totalPrice * 0.15 * 100) / 100
      }
    }
    if (shopId === 'mikaza') {
      if (['mikaza8'].indexOf((promoCode || '').toLowerCase()) !== -1) {
        totalDiscount += Math.round(totalPrice * 0.1 * 100) / 100
      }
    }

    // if (shopId === 'bierhaus') {
    //   let winePrices = []
    //   products.forEach(({sub, price, quantity}) => {
    //     if (sub.indexOf('WINE') !== -1) { // is wine
    //       for (let step = 0; step < quantity; step++) {
    //         winePrices.push(price)
    //       }
    //     }
    //   })
    //   console.log("🍷" + winePrices)
    //   const sorted = winePrices.sort((a, b) => -(a - b))
    //   let discounts = 0
    //   const numOfFreeWine = Math.floor(sorted.length / 2)
    //   for (let step = 0; step < numOfFreeWine; step++) {
    //     // set a wine to free
    //     // remove highest priced item
    //     const expensive = sorted.shift()
    //     const cheap     = sorted.shift()
    //     discounts+=cheap
    //   }
    //   totalDiscount += discounts
    // } else {
    //   // return 0
    // }

    if (shopId === 'bierhaus') {
      let hasFlatBread = false
      let count = 0
      products.forEach(({sub, price, quantity}) => {
        if (sub.indexOf('Small Plates') !== -1) { // is wine
          for (let step = 0; step < quantity; step++) {
            count++
          }
        }
        if (((promoCode || '').toLowerCase() === 'flatbread') && (sub.indexOf('Flatbreads') !== -1)) { // is wine
          hasFlatBread = price
        }
      })
      let discounts = Math.floor(count / 3) * 2
      totalDiscount += discounts

      if ((totalPrice >= 30) && (hasFlatBread)) {
        totalDiscount += Number(hasFlatBread)
      }

      if (['kitchen', 'eat', 'dad', 'dad15', 'nomess', 'noprep', 'community', 'nodriver'].indexOf((promoCode || '').toLowerCase()) !== -1) {
        totalDiscount += Math.round(totalPrice * 0.15 * 100) / 100
      }

    }
    return Math.round(totalDiscount * 100) / 100
  } catch (e) {
    return 0
  }


}
