
import React, { Component } from 'react';
import './about.scss'

export default function () {
  console.log('our story rendering')
  return(
    <div className="about-page">
      <article className="post our-story">
        <h1>Our Story</h1>

        <p>

          First founded by Joseph Calderone and Luigi Devito, Two Guys From Italy has progressed from a relatively unknown franchise to today's privately owned, successfully developed eatery. We take pride in offering an authentic experience with a vast menu of genuine Italian cuisine and a few alternatives for those with a different palette. Take a sample from our extensive bar menu and enjoy only the finest, premium ingredients prepared by seasoned chefs who've been part of the Two Guys family for more than 20 years.

         </p>

          <p>

            Two Guys From Italy has developed its services with the customer in mind, offering free delivery within a 5 mile radius, as well as catering for all occasions. From weddings to baby showers, corporate events to family reunions, Two Guys has satisfied even the most voracious appetites. We've serviced the Glendale community for 20 years with only the best reviews of our food. An additional private room within the restaurant offers customers an in house option for their function. Consult with us about your event and we'll create a menu and budget that's just right for you.


          </p>

          <p>
            {/* <img className="right" src="https://s3-media4.fl.yelpcdn.com/bphoto/8bsXBj_2t7GpSxxnlgMHlw/o.jpg" alt=""/> */}

            Whether you dine in or take out, you get it any way you want it!</p>

      </article>


    </div>
  )
}
